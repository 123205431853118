export function ExportCsv(data) {

    const rows = [
        ["Name", "Telephone", "Email", "Message", "News letters & collect"]
    ];

    data.allStrapiContactEntries.edges.map((information) => {
        const table = rows
        table.push([information.node.Name, information.node.Telephone, information.node.Email, information.node.Message, information.node.InscriptionCollecteEtNewsLetter])
        console.log(table)
    })


    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "coordonnes.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
    console.log(data)
}