import React from "react";
import Layout from "../components/layout";
import {ExportCsv} from "../function/exportCsv";
import {graphql} from "gatsby";

function loginAdmin() {
    if (typeof document !== 'undefined') {
        const login = prompt("Password", "")
        return login
    }
}

const Admin = ({data}) => {
    return (
        <Layout>
            {
                loginAdmin() === "ebisuadmin" ?
                    <button onClick={e => ExportCsv(data)}>Export Coordonnées</button>
                    :
                    <></>
            }
        </Layout>
    )
}

export default Admin

export const AdminQuery = graphql`
query AdminQuery {
  allStrapiContactEntries {
    edges {
      node {
        Email
        InscriptionCollecteEtNewsLetter
        Message
        Name
        Telephone
      }
    }
  }
}
`