/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import "../styles/components/layout.css"

const Layout = ({children, className}) => {
    return (
        <>
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.15.3/css/all.css"
                  integrity="sha384-iKbFRxucmOHIcpWdX9NTZ5WETOPm0Goy0WmfyNcl52qSYtc2Buk0NCe6jU1sWWNB"
                  crossOrigin="anonymous"/>
            <main className={`${className} padding-main`}>{children}</main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
